<template>
	<!-- 下单系统 -->
	<div class="title rsc fww">
		<div  class="rbc txt" :style="{'color': color, 'border-top-color': bgColor ? bgColor : ''}">
			<div class="line"></div>
			<div>{{ title }}</div>
			<div class="line"></div>
		</div>
		<div class="subtitle" :style="{'color': color ? color : '#666'}">{{ eng }}</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: String,
			eng: String,
			bgColor: String,
			color: String,
		},
	}
</script>

<style lang="scss" scoped>
	.title {
		// width: 500px;
		position: relative;

		.txt {
			font-size: 2.2rem;
			font-family: Microsoft YaHei;
			margin: 0 auto;
			
			.line {
				width: 80px;
				height: 2px;
				margin: 0 30px;
				background: #dadada;
			}
		}

		.subtitle {
			color: #666;
			position: absolute;
			font-family: PingFang SC;
			font-style: italic;
			left: 50%;
			top: 110%;
			transform: translateX(-50%);
		}
	}
</style>
