<template>
	<div>
		<div class="container csc">
			<div class="top-cover">
				<img src="../../assets/order/cover.png" alt>
			</div>
			<div class="nav-box rac">
				<div class="order-item ccc" v-for="(item, index) in transports" :key="index">
					<el-image style="width: 105px; height: 105px" :src="item.icon"></el-image>
					<div class="desc cac">
						<div class="txt">{{item.txt}}</div>
						<div>{{item.engTxt}}</div>
					</div>
					<div class="item-active">
						<div class="wrap ccc" @click="$router.push(path + '?index=' + index)">
							<el-image style="width: 105px; height: 105px" :src="item.icon"></el-image>
							<div class="desc cac">
								<div class="txt">{{item.txt}}</div>
								<div>{{item.engTxt}}</div>
								<el-image style="width: 30px; height: 30px;margin-top: 10px;"
									:src="require('../../assets/order/back.png')"></el-image>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="order-content csc">
				<t-title title="如何下单?" eng="HOW TO PLACE THE ORDER" />

				<div class="steps rsc">
					<div class="step-item cbc" v-for="(item, index) in steps" :key="index">
						<div class="line-box">
							<div class="line"></div>
							<div class="circle rcc">
								<div class="inner"></div>
							</div>
						</div>
						<img :src="item.icon" width="120" height="120">
						<div style="margin-top: 15px;">{{item.txt}}</div>
						<span>{{item.tips}}</span>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import TTitle from 'components/title.vue'
	export default {
		components: {
			TTitle
		},
		data() {
			return {
				active: 0,
				path: 'orderSystem/overbooking',
				transports: [{
					icon: require('@/assets/home/air_freight.png'),
					txt: '空运',
					engTxt: 'Air freight'
				}, {
					icon: require('@/assets/home/shipping.png'),
					txt: '海运',
					engTxt: 'shipping'
				}, {
					icon: require('@/assets/home/land_transportation.png'),
					txt: '陆运（泰国）',
					engTxt: 'Land transportation'
				}, {
					icon: require('@/assets/home/shopping.png'),
					txt: '代购',
					engTxt: 'shopping'
				}],
				steps: [{
					icon: require('@/assets/order/registration.png'),
					txt: '注册账号',
					tips: '注册登录网站后，选择您要下单的模式'
				}, {
					icon: require('@/assets/order/write.png'),
					txt: '填写信息',
					tips: '填写输入表格相关信息，比选择提交'
				}, {
					icon: require('@/assets/order/receive.png'),
					txt: '完成包裹',
					tips: '超凡物流下单系统会为您实时跟踪'
				}]
			};
		},
	};
</script>

<style scoped lang="scss">
	.container {
		width: 100%;
		margin: 0 auto;
		height: 1261px;
		background: #fff;
		position: relative;

		.top-cover {
			width: 100%;
			position: absolute;
			// top: 60px;
			background: #536378;
			height: 450px;


			img {
				margin: 0 auto;
				max-width: 1920px;
				min-width: 1200px;
				width: 100%;
				height: 100%;
				display: block;
			}
		}

		.nav-box {
			width: 1200px;
			position: absolute;
			z-index: 9;
			top: 330px;
			background: #fff;
			box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
			border-radius: 10px;


			.desc {
				padding-top: 20px;
				height: 80px;
				color: #7e7e7e;

				.txt {
					font-weight: bold;
					font-size: 1.2rem;
					color: #333333;
					font-family: Microsoft YaHei;
				}

			}

			.order-item {
				width: 25%;
				height: 235px;
				cursor: pointer;
				position: relative;
			}

			.item-active {
				display: none;
				width: 300px;
				height: 263px;
				transition: all 1s linear;

				.wrap {
					height: 100%;

					.desc {
						color: #fff;
						height: 120px;

						.txt {
							font-size: 1.2rem;
							color: #fff;
							font-family: Microsoft YaHei;
						}

					}
				}
			}

			.order-item:hover .item-active {
				display: block;
				position: absolute;
				opacity: 1;
				top: -14px;
				left: 0;
				display: flex;
				flex-direction: column;
				border-radius: 10px;
				background: #3F7CF8;
				box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
			}
		}

		.order-content {
			width: 100%;
			height: 811px;
			background: #F2F6FF;
			position: absolute;
			top: 450px;
			padding-top: 236px;


			.steps {
				width: 1200px;
				height: 250px;
				margin-top: 70px;
				color: #333333;
				font-size: 1.3rem;
				font-weight: bold;
				// background: grey;

				span {
					font-weight: normal;
					;
					font-size: 1.1rem;
					margin-top: 28px;
				}
			}
		}
	}

	.step-item {
		width: 33.333%;
		height: 100%;

		.line-box {
			width: 100%;
			height: 22px;
			position: relative;
			margin-bottom: 22px;

			.line {
				height: 4px;
				background: #DADBDE;
				border-radius: 2px;
				margin-top: 9px;
			}

			.circle {
				position: absolute;
				width: 22px;
				height: 22px;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 50%;
				background: #B1CBFF;

				.inner {
					width: 12px;
					height: 12px;
					background: #3F7CF8;
					border-radius: 50%;
				}
			}
		}
	}
</style>
